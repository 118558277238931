div.data-table {
    min-height: 768px;
    font-size: 1.7vm!important;
}

.table-control-btns .btn {
    margin-right: 0.1pc;
}

table {
    background-color: #001e00 !important;
    font-weight: bold;
    color: yellow;
}

table.table-bordered td, table.table-bordered th {
    border: 1px solid darkorange !important;
    font-size: 0.8pc;
}

thead th {
    padding: 1em !important;
}

td {
    color: yellow !important;
    padding: 0.5em !important;
    vertical-align: middle !important;
}

td.action-col, th.action-col {
    color: white !important;
}

tr {
    border: none;
}

tr.outOfStock td.unitsTotal {
    color: orangered !important;
    font-size: 0.7em;
}

tr.outOfStock td.sku {
    color: orangered !important;
}

table td.table-small-font {
    font-size: 0.8em;
}

table.stockTable thead th:hover {
    color: #001e00 !important;
    background-color: yellow !important;
}

table.stockTable-disabled, table.stockTable-disabled input {
    font-size: 0.8em;
}

table.stockTable-disabled input {
    background-color: lightslategrey !important;
    border: none;
}

table.transferTable td, table.transferTable {
    background-color: slateblue;
    font-size: 1.1em;
}

table.transferTable input {
    font-size: 1.1em;
}

caption {
    font-size: 0.8em;
    text-align: right;
    color: orange;
}

input.search {
    background-color: darkorange;
    color: #001e00 !important;
    font-weight: bold;
}

input.search::placeholder {
    color: #001e00 !important;
    font-weight: bold;
}

input.search {
    border: 2px solid darkgoldenrod !important;
}

.Modal {
    position: absolute;
    top: 40px;
    left: 40px;
    right: 40px;
    bottom: 40px;
    background-color: papayawhip;
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rebeccapurple;
}

div.message-card {
    margin: 0 0 1em 0;
    text-align: center;
    background-color: #001e00;
    border: 1px solid yellow;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    border-radius: 7px;
}

.alert {
    font-weight: bold;
    font-size: 0.8em
}