div.login div.form-field {
    display: inline-block;
    margin: 0 1em 1em 0;
}

div.login div.logout-button-field {
    text-align: right;
}

div.login input.login {
    display: block;
}

span.welcome {
    display: inline-block;
    color: #001e00;
    margin: 0 1em 0 1em;
}

.logout-button-field button {
    margin: 0.25em;
}