button.close-button {
    margin-left: auto;
}

.modal-button-cell {
    text-align: right;
}

label {
    margin: 0.5em;
    font-weight: bold;
}

form.transfer-form {
    background-color: yellow;
    color: #001e00;
}

form.transfer-form-danger {
    background-color: #530000;
    color: white;
    min-height: 350px;
}

button.delete-btn, button.cancel-btn {
    margin: 2pc;
}

button.delete-btn:hover, button.cancel-btn:hover {
    transform: scale(1.75);
    transition: all 100ms ease-in-out;
}

button.stockActionButton {
    margin-bottom: 1vmax;
}

button.stockActionButton:hover {
    transform: scale(1.25);
    transition: all 100ms ease-in-out;
}

input {
    border-style: none !important;
}

.transfer {
    vertical-align: top;
}

.xfer-button {
    margin: 1em 0 0 0;
    text-align: right;
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 100ms ease-in-out;
    z-index: 10;  /* prevents buttons lingering after clicked */
}

.ReactModal__Overlay--after-open {
    opacity: 1;
    /* overflow-y: scroll !important; */
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}

.ReactModal__Content {
    min-width: 35vw!important;
}