html {
    /* background-color: #001e00; */
    height: 100%;
}

body {
    background-color: #333;
}

div.app-main {
    margin: 1vh 1vh 0 1vh;
    padding: 1vh;
    color: #fcfcfc;
    background-color: #001a00;
    min-height: 90vh;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
}

h1, h2, h3, h4, h5, h6 {
    color: yellow;
}

div.account_type h1 {
    color:#001a00;
}

button.table-btn {
    transition: all 200ms ease-in-out
}

button.table-btn:hover {
    transform: scale(1.25);
    z-index: 10;
}